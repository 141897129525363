(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("signalR"));
	else if(typeof define === 'function' && define.amd)
		define(["signalR"], factory);
	else if(typeof exports === 'object')
		exports["msgpack"] = factory(require("signalR"));
	else
		root["signalR"] = root["signalR"] || {}, root["signalR"]["protocols"] = root["signalR"]["protocols"] || {}, root["signalR"]["protocols"]["msgpack"] = factory(root["signalR"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__1__) => {
return 